@media all and (min-width: 200px) {
  .ReportsTable {
    padding: -10px 0;
    width: 100%;
  }

  .ReportsTable Form {
    margin: 0 auto;
    text-align: left;
    max-width: 200px;
  }
  .ReportTable h2 {
    text-align: center;
  }
  .ReportTable h5 {
    text-align: center;
  }
  th,
  td {
    text-align: left;
    padding: 0.5rem;
    font-size: small;
  }
  .ReportsTable Button {
    margin-left: -1em;
    font-size: small;
    text-align: left;
  }
}
