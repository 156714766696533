@media all and (min-width: 480px) {
  .Thanks {
    padding: 20px 0;
    text-align: center;
  }

  .Message {
    padding: 0px 0;
    text-align: center;
  }
}
