@media all and (min-width: 600px) {
  .ReviewContactForm {
    padding: 20px 0;
    width: 100%;
  }

  .ReviewContactForm form {
    margin: 0 auto;
    text-align: left;
    max-width: 60%;
  }
  .ReviewContactForm h2 {
    text-align: center;
    margin-top: auto;
  }
  .divider {
    width: 5px;
    height: auto;
    display: inline-block;
  }
  .PrimaryButton {
    font-size: smaller;
    text-align: center;
    background-color: dimgray;
  }
  .SecondaryButton {
    font-size: smaller;
    text-align: center;
    background-color: grey;
  }
  table {
    border-collapse: collapse;
    border-spacing: 0;
    width: 100%;
    max-width: 100%;
  }

  thead th {
    border-bottom: 2px solid #dedede;
  }

  tfoot th {
    border-top: 2px solid #dedede;
  }

  td {
    border-bottom: 1px solid #dedede;
  }

  th,
  td {
    text-align: left;
    padding: 0.5rem;
    font-size: medium;
  }
  hr {
    width: 100%;
    height: 1px;
    background: dimgray;
  }
}
