@media all and (min-width: 450px) {
  .WelcomePage {
    padding: 60px 0;
    width: 100%;
    
  }

  .inline-block-center {
    width: 30%;
    margin-left: 35%;
    margin-right: 35%;
    text-align: center;
    margin-top: 20px;
  }
  .inline-block-center {
    display: inline-block;
    text-align: center;

  }

  .h1 {

    text-align: center;
    margin-bottom: 2rem;

  }
}