@media all and (min-width: 450px) {
  .GroupQtrlyReport {
    padding: 20px 0;
    width: 100%;
  }

  .GroupQtrlyReport form {
    margin: 0 auto;
    text-align: left;
    max-width: 450px;
  }
  .GroupQtrlyReport h2 {
    text-align: center;
  }
}
