@media all and (min-width: 450px) {
  .ActiveGroupAmend {
    padding: 20px 0;
    width: 100%;
  }

  .ActiveGroupAmend form {
    margin: 0 auto;
    text-align: left;
    max-width: 450px;
  }
  .ActiveGroupAmend h2 {
    text-align: center;
  }
}
