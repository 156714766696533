@media all and (min-width: 450px) {
    .RegSignupForm {
      padding: 20px 0;
      width: 100%;
    }
  
    .RegSignupForm form {
      margin: 0 auto;
      text-align: left;
      max-width: 450px;
    }
    .RegSignupForm h2 {
      text-align: center;
    }
  }
  