@media all and (min-width: 450px) {
  .IntergroupAdmin {
    padding: 2px 0;
    width: 100%;
  }

  .IntergroupAdmin form {
    margin: 0 auto;
    text-align: left;
    max-width: 450px;
  }
  .IntergroupAdmin h2 {
    text-align: center;
  }
  .IntergroupAdmin h3 {
    text-align: center;
  }
}
