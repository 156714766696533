@media all and (min-width: 450px) {
  .Contacts {
    padding: 60px 0;
    width: 100%;
  }

  .Contacts form {
    margin: 0 auto;
    text-align: left;
    max-width: 450px;
  }
  .Contacts h2 {
    text-align: center;
  }
}
