@media all and (min-width: 480px) {
  .Signup {
    padding: 20px 0;
    text-align: center;
  }

  .Signup form {
    padding: 20px 0;
    margin: 0 auto;
    max-width: 320px;
    text-align: left;
  }

  .checkbox {
    padding: 0 0;
    text-align: center;
  }

  .Link {
    padding: 0 0;
    text-align: left;
  }
  .divider {
    width: 5px;
    height: auto;
    display: inline-block;
  }
}
