@media all and (min-width: 600px) {
  .ReviewContactForm {
    padding: 20px 0;
    width: 100%;
  }

  .ReviewContactForm form {
    margin: 0 auto;
    text-align: left;
    max-width: 60%;
  }
  .ReviewContactForm h2 {
    text-align: center;
    margin-top: auto;
  }
  .divider {
    width: 5px;
    height: auto;
    display: inline-block;
  }
  .PrimaryButton {
    font-size: smaller;
    text-align: center;
    background-color: dimgray;
  }
  .SecondaryButton {
    font-size: smaller;
    text-align: center;
    background-color: grey;
  }
  .myth,
  .mytable {
    text-align: left;
    padding: 0.5rem;
    font-size: xx-large;
    font-weight: 500;
  }
  hr {
    width: 100%;
    height: 1px;
    background: dimgray;
  }
}
